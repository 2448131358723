@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: url(/src/assets/fonts/roboto-regular-webfont.woff2) format(woff2),
         url(/src/assets/fonts/roboto-regular-webfont.woff) format(woff);
}
@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: url(/src/assets/fonts/roboto-medium-webfont.woff2) format(woff2),
         url(/src/assets/fonts/roboto-medium-webfont.woff) format(woff);
}
@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: url(/src/assets/fonts/roboto-bold-webfont.woff2) format(woff2),
         url(/src/assets/fonts/roboto-bold-webfont.woff) format(woff);
}
@font-face {
    font-family: 'Dancing Script';
    src: url(/src/assets/fonts/dancingscript-variablefont_wght-webfont.woff2) format(woff2),
         url(/src/assets/fonts/dancingscript-variablefont_wght-webfont.woff) format(woff);
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 840px,
);
@mixin small-screen {
    @media (max-width: 540px) {
      @content;
    }
}
@mixin medium-screen {
    @media (max-width: 840px) {
      @content;
    }
}
@mixin large-screen {
    @media (min-width: 541px) {
      @content;
    }
}

$primary: #b18e96; //#355070;
$secondary: #3d2926;;
$floral-white: #FFFBF0;
$cambridge-green: #919F8B;

$enable-rounded: false;

// Import all of Bootstrap's CSS
@import "bootstrap/scss/bootstrap";

body {
    background: url(/src/assets/blossom.jpg);
    background-size: cover;
    font-family: 'Roboto', sans-serif;
    position: relative;
    min-height: 100vh;
    min-height: 100dvh;
    margin: 0;
    color: $secondary;
    line-height: 1.5;
}

h1, h2 {
    font-family: 'Dancing Script', sans-serif;
    font-weight: bold;
}
h3, h4, h5, h6 {
    font-weight: medium;
}

.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}

.script {
    font-family: 'Dancing Script', sans-serif;
}
.signature {
    text-decoration: underline;
    text-underline-offset: 1rem;
    text-decoration-color: $primary;
}

header, footer {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}

header .stitching {
    border-top: 1px white dashed;
    border-bottom: 1px white dashed;
}
footer .stitching {
    border-top: 1px white dashed;
}

.container {
    position: relative;
    min-height: 100vh;
    min-height: 100dvh;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.container.site-content {
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: rgba(68, 50, 93, 0.4) 0px 4rem 8rem -2rem, rgba(0, 0, 0, 0.3) 0px 2rem 5rem -3rem;
}

#title {
    @include small-screen {
        margin-bottom: 1rem;
    }
    @include large-screen {
        margin-bottom: 1.5rem;
    }
}

header.bg-primary {
    @include small-screen {
        padding-top: 3rem !important;
    }
}

#header-container {
    @include small-screen {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
    @include large-screen {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}

li.nav-item {
    & + .nav-item {
        @include large-screen() {
            margin-left: 0.25rem;
        }
    }
    a.nav-link {
        text-decoration: underline;
        text-underline-offset: 0.5rem;
        // text-decoration-style: dashed;
        color: white;
        box-sizing: border-box;
        + .nav-link {
            margin-left: 1rem;
        }
        &:hover {
            text-decoration: none;
        }
        &.active {
            text-decoration: none;
            border: dashed 1px;
        }
        @include medium-screen {
            font-size: 1rem;
        }
        @include small-screen {
            font-size: 0.75rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }
}

a.rsvp-link {
    text-decoration: none;
    li:hover {
        background-color: $primary-bg-subtle;
        transition: 0.3s;
    }
}

hr {
    border-style: dashed;
}

section {
    @extend .mb-5;
    display: none;
}

section.active {
    display: block;
}

#language-selector {
    position: absolute;
    right: 0.5rem;
    z-index: 5;
    margin-top: 2rem;
    margin-right: 1rem;
    @include small-screen {
        margin-top: 0.5rem;
        margin-right: 0.25rem;
    }
}

.gallery {
    img {
        border: $primary 1px dashed;
        @include small-screen {
            padding: 0.5rem;
        }
        @include large-screen {
            padding: 1rem;
        }
        cursor: pointer;
    }
}

hr.bouquet {
    margin-top: 80px;
}
img.bouquet {
    width: 160px;
    margin-top: -120px;
}

img.couple {
    width: 160px;
}

.list-group, .list-group-item {
    border: none;
    background-color: transparent;
}
.list-group-item {
    padding-left: 0;
    padding-right: 0;
}

#schedule {
    .headline {
        font-weight: bold;
        font-size: large;
    }
    .list-group {
        margin-bottom: 1px solid $primary;
    }
    .list-group-item {
        @extend .py-4;
        time {
            margin-right: 0.125rem;
            font-size: large;
            font-weight: bold;
            color: $primary;
        }
    }
    .list-group-item + .list-group-item {
        border-top: 1px dashed $primary;
    }
}

#q-and-a {
    p {
        padding: 1.5rem 0;
        margin: 0;
    }
    p + p{
        border-top: 1px dashed $primary;
    }
    .question {
        font-weight: bold;
        font-size: large;
    }
}

a {
    color: $secondary;
}

#lyra-image-container {
    position: relative;
    cursor: pointer;

    &::after, &::before {
        transition: opacity 0.1s ease-in-out;
    }

    &::after {
        content: "woof!";
        position: absolute;
        right: -50px;
        top: 0;
        font-family: 'Dancing Script', sans-serif;
        font-size: 1.5rem;
        font-weight: bold;
        margin-left: 0.5rem;
        background-color: white;
        padding: 0.5rem 1rem;
        border: 1px solid $secondary;
        border-radius: 0.5rem;
        width: 80px;
        height: 60px;
        opacity: 0;
    }

    &::before {
        content: "";
        position: absolute;
        right: 30px;
        z-index: 5;
        top: 27px;
        width: 0;
        height: 0;
        background-color: rgb(255, 255, 255);
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid black;
        opacity: 0;
    }

    &.woof {
        &::after, &::before {
            opacity: 1;
        }
    }
}

body.fireworks {
    $particles: 100;
    $width: 500;
    $height: 500;

    // Create the explosion...
    $box-shadow: ();
    $box-shadow2: ();
    @for $i from 0 through $particles {
        $box-shadow: $box-shadow,
                    calc(random($width) - $width / 2) + px
                    calc(random($height) - $height / 1.2) + px
                    $primary;
        $box-shadow2: $box-shadow2, 0 0 #fff
    }
    @mixin keyframes ($animationName) {
        @-webkit-keyframes #{$animationName} {
            @content;
        }

        @-moz-keyframes #{$animationName} {
            @content;
        }

        @-o-keyframes #{$animationName} {
            @content;
        }

        @-ms-keyframes #{$animationName} {
            @content;
        }

        @keyframes #{$animationName} {
            @content;
        }
    }

    @mixin animation-delay ($settings) {
        -moz-animation-delay: $settings;
        -webkit-animation-delay: $settings;
        -o-animation-delay: $settings;
        -ms-animation-delay: $settings;
        animation-delay: $settings;
    }

    @mixin animation-duration ($settings) {
        -moz-animation-duration: $settings;
        -webkit-animation-duration: $settings;
        -o-animation-duration: $settings;
        -ms-animation-duration: $settings;
        animation-duration: $settings;
    }

    @mixin animation ($settings) {
        -moz-animation: $settings;
        -webkit-animation: $settings;
        -o-animation: $settings;
        -ms-animation: $settings;
        animation: $settings;
    }

    @mixin transform ($settings) {
        transform: $settings;
        -moz-transform: $settings;
        -webkit-transform: $settings;
        -o-transform: $settings;
        -ms-transform: $settings;
    }

    .fireworks-before, .fireworks-after {
        z-index: 100;
        position: fixed;
        width: 5px;
        height: 5px;
        border-radius: 3px;
        box-shadow: $box-shadow2;
        @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
    }
        
    .fireworks-after {
        @include animation-delay((1.25s, 1.25s, 1.25s));
        @include animation-duration((1.25s, 1.25s, 6.25s));
    }
            
    @include keyframes(bang) {
        to {
            box-shadow:$box-shadow;
        }
    }
        
    @include keyframes(gravity)  {
        to {
            @include transform(translateY(200px));
            opacity: 0;
        }
    }
        
    @include keyframes(position) {
        0%, 19.9% {
            margin-top: 10%;
            margin-left: 40%;
        }
        20%, 39.9% {
            margin-top: 40%;
            margin-left: 30%;
        }
        40%, 59.9% {  
            margin-top: 20%;
            margin-left: 70%
        }
        60%, 79.9% {  
            margin-top: 30%;
            margin-left: 20%;
        }
        80%, 99.9% {  
            margin-top: 30%;
            margin-left: 80%;
        }
    }

    .gallery img {
        border-width: 3px;
    }
}

body {
    visibility: visible;
}